import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { LARGE_LIMIT, ORDER, PROJECT_DOMAINS } from 'src/constants';
import UserService from 'src/services/user';

import styles from './styles.module.css';

const ActionBar = (props) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        try {
          // formValues are for view only, searchConditions in viewModel are handled separately
          const JsonString = sessionStorage.getItem('formValues');
          const formValues = (JsonString && props.useSessionStorage) ? JSON.parse(JsonString) : null;

          if (props.profile.admin || props.profile.isTeamLeader) {
            const { users } = await UserService.getUsers({
              limit: LARGE_LIMIT,
              order: 'department',
              pmStatus: 'enable',
              team: props.profile.admin ? undefined : props.profile.teamId
            });
            setUser(users);
          }

          form.setFieldsValue({
            keyword: formValues?.keyword ?? undefined,
            domain: formValues?.domain ?? undefined,
            status: formValues?.status ?? undefined,
            isTemplate: formValues?.isTemplate ?? undefined,
            searchAngle: formValues?.searchAngle ?? undefined,
            userId: undefined,
            order: formValues?.order ?? ORDER.createDateDesc
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }

    return () => { mounted = false; };
  }, [form, props.profile, props.useSessionStorage]);


  const onSearch = () => {
    const formValues = form.getFieldsValue();

    if (props.useSessionStorage) {
      sessionStorage.setItem('formValues', JSON.stringify(formValues));
    }

    props.onQuery(formValues);
  };

  return (
    <div className={styles.box}>
      <Form form={form}>
        <Row justify="space-between">
          <Col>
            <Form.Item name="keyword" style={{ marginBottom: 5 }}>
              <Input placeholder="搜尋（專案名稱、專案ID）" style={{ width: 725 }} />
            </Form.Item>

            <Row align="middle">
              <div>篩選條件：</div>
              <Row>
                <Form.Item name="domain" style={{ marginBottom: 5, marginRight: 5 }}>
                  <Select
                    style={{ width: 150 }}
                    placeholder="所屬領域"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                  >
                    {PROJECT_DOMAINS.map((domain) => (
                      <Select.Option key={domain} value={domain}>{domain}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="status" style={{ marginBottom: 5, marginRight: 5 }}>
                  <Select style={{ width: 130 }} placeholder="專案狀態" allowClear>
                    <Select.Option value="notRun">未啟動</Select.Option>
                    <Select.Option value="run">運作中</Select.Option>
                    <Select.Option value="outOfDate">已過期</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="isTemplate" style={{ marginBottom: 5, marginRight: 5 }}>
                  <Select style={{ width: 170 }} placeholder="是否為公版專案" allowClear>
                    <Select.Option value={undefined}>顯示全部</Select.Option>
                    <Select.Option value>僅顯示公版專案</Select.Option>
                    <Select.Option value={false}>僅顯示非公版專案</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="searchAngle" style={{ marginBottom: 5, marginRight: 5 }}>
                  <Select style={{ width: 170 }} placeholder="切角分析" allowClear>
                    <Select.Option value="brand">品牌分析切角</Select.Option>
                    <Select.Option value="category">品類分析切角</Select.Option>
                  </Select>
                </Form.Item>

                {
                  (props.profile.admin || props.profile.isTeamLeader) ? (
                    <Form.Item name="userId" style={{ marginBottom: 5 }}>
                      <Select
                        placeholder="負責人"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 220 }}
                      >
                        {user.map((t) => (
                          <Select.Option value={t.id} key={t.id}>{`${t.teamName} - ${t.name}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }

              </Row>
            </Row>
          </Col>


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {props.hasOrder
              ? (
                <Form.Item name="order" label="排序依：" style={{ marginBottom: 5 }}>
                  <Select style={{ width: 160 }}>
                    <Select.Option value={ORDER.startDateDesc}>專案開始日新到舊</Select.Option>
                    <Select.Option value={ORDER.startDateAsc}>專案開始日舊到新</Select.Option>
                    <Select.Option value={ORDER.endDateDesc}>專案結束日新到舊</Select.Option>
                    <Select.Option value={ORDER.endDateAsc}>專案結束日舊到新</Select.Option>
                    <Select.Option value={ORDER.createDateDesc}>專案建立日新到舊</Select.Option>
                    <Select.Option value={ORDER.createDateAsc}>專案建立日舊到新</Select.Option>
                  </Select>
                </Form.Item>
              )
              : (
                <div />
              )}

            <Row justify="end" style={{ paddingBottom: 10 }}>
              <Button onClick={() => {
                form.resetFields();
                form.setFieldsValue({
                  order: ORDER.createDateDesc
                });
              }}
              >
                清空篩選條件
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={onSearch}
              >
                套用
              </Button>
            </Row>
          </div>
        </Row>

      </Form>
    </div>
  );
};

ActionBar.propTypes = {
  onQuery: PropTypes.func.isRequired,
  hasOrder: PropTypes.bool,
  useSessionStorage: PropTypes.bool,
  profile: PropTypes.object.isRequired
};

ActionBar.defaultProps = {
  hasOrder: false,
  useSessionStorage: false
};

export default ActionBar;
